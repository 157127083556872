import axios from 'axios';

class ChatCompletionsApi{

    static API_ENVIRONMENT_URL = process.env.REACT_APP_API_ENVIRONMENT_URL;

    async wait(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
    
      async submit(question, userEmailAddress, userIPAddress, retryCount) {

        const postBody = {
          question,
          userEmailAddress,
          userIPAddress,
        };
    
        try {
          const askApi = `${ChatCompletionsApi.API_ENVIRONMENT_URL}/api/chat/ask`;
          const response = await axios.post(askApi, postBody);
    
          const retry = response.data.message && (!retryCount || retryCount < 10);
          if (retry) {
            // wait for 1 second then retry
            await this.wait(1000);
            let newRetryCount = retryCount ? (retryCount + 1) : 1;
            return await this.submit(question, userEmailAddress, userIPAddress, newRetryCount);
          } else if(response.data) {
            return response.data;
          } else {
            // else revert to unhandled error
            throw new Error('maxed out initial retries')
          }
        } catch (error) {
          // handle retries for other potential errors  
          console.log(error);
          const retry = (!retryCount || retryCount < 5);
          if (retry) {
            await this.wait(1000); // Wait for one second
            let newRetryCount = retryCount ? retryCount + 1 : 1;
            return await this.submit(question, userEmailAddress, userIPAddress, newRetryCount);
          } else {
            //throw error;
            console.log(error);
            return {error: 'chat api error'}
          }
        }
      }
    }
      
    
export default ChatCompletionsApi;
