import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
        numberOfTrialQuestionsAsked: 0,
        emailAddressSubmitted: false,
        userOkdCheckInboxMessage: false,
        emailPromptCanceled: false,
        confirmationEmailSent: false,
        membershipSubmitted: false,
        userIsAnonymous: true,
        userIsEmailOnlyMember: false,
        setEmailRegistrationEmailConfirmedDate: null,
    },
}

export const userTrialSlice = createSlice({
  name: 'userTrial',
  initialState,
  reducers: {
    incrementNumberOfTrialQuestionsAsked: (state) => {
      state.value.numberOfTrialQuestionsAsked += 1
    },
    setEmailAddressSubmitted: (state) => {
      state.value.emailAddressSubmitted = true;
    },
    setEmailPromptCanceled: (state, action) => {
      state.value.emailPromptCanceled = action.payload;
    },
    setConfirmationEmailSent: (state) => {
      state.value.confirmationEmailSent = true;
    },
    setMembershipPromptCanceled: (state, action) => {
      state.value.emailPromptCanceled = action.payload;
    },
    setUserOkdCheckInboxMessage: (state, action) => {
      state.value.userOkdCheckInboxMessage = action.payload;
      //state?.persistedReducer?.userTrial?.value?.emailAddressSubmitted
    },
    setUserIsAnonymous: (state, action) => {
      state.value.userIsAnonymous = action.payload;
    },
    setUserIsEmailOnlyMember: (state, action) => {
      state.value.userIsEmailOnlyMember = action.payload;
    },
    setEmailRegistrationEmailConfirmedDate: (state, action) => {
      state.value.emailRegistrationEmailConfirmedDate = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
    incrementNumberOfTrialQuestionsAsked, 
    setEmailAddressSubmitted, 
    setEmailPromptCanceled,
    setConfirmationEmailSent,
    setMembershipPromptCanceled,
    setUserOkdCheckInboxMessage,
    setUserIsAnonymous,
    setUserIsEmailOnlyMember,
    setEmailRegistrationEmailConfirmedDate
} = userTrialSlice.actions

export default userTrialSlice.reducer
