import { useState } from 'react';
import {
  Text,
  Checkbox,
  Link,
  Button,
  Flex,
  Box,
  FormErrorMessage,
  useTheme,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TermsAndConditionsModal from './terms-and-conditions/terms-and-conditions-modal';
import SubscriptionType from '../subscription-type/subscription-type';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAccordionItemIndex,
  setTermsAndConditionsAccepted,
} from '../../../features/registration/registrationSlice';

const OtherInfo = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const termsAndConditions = useSelector((state) => state.registration.value.termsAndConditions);

  const validationSchema = Yup.object().shape({
    selectedPlanId: Yup.string().required('Plan selection is required'),
    termsAndConditions: Yup.boolean().oneOf([true], 'Accepting the Terms and Conditions is required'),
  });

  const handleSubmit = () => {
    setNextClicked(true);
    if (formik.isValid && !formik.errors.selectedPlanId) {
      dispatch(setAccordionItemIndex(3));
      setNextClicked(false);
    } else {
    }
  };

  const formik = useFormik({
    initialValues: {
      termsAndConditions: termsAndConditions,
    },
    validationSchema,
    onSubmit: () => {
      // This will be called only if the form is valid
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mt="4">
        <SubscriptionType formik={formik} nextClicked={nextClicked} />
      </Box>

      <Flex alignItems="center" justifyContent="center" mt="4" mb="1" flexDirection="column">
        <Checkbox
          id="termsAndConditions"
          name="termsAndConditions"
          isChecked={termsAndConditions}
          onChange={(x) => {
            const isChecked = x.target.checked;
            dispatch(setTermsAndConditionsAccepted(isChecked));
            formik.setFieldValue('termsAndConditions', isChecked);
          }}
          onBlur={formik.handleBlur}
          mt="2"
          mb="2"
          borderColor={nextClicked && formik.errors.termsAndConditions ? theme.colors.red['500'] : undefined}
        />
        <Text mb="0.8" ml="2">
          I have accepted the&nbsp;
          <Link
            onClick={() => setShowModal(true)}
            style={{
              color: 'blue',
              textDecoration: 'underline',
            }}
          >
            Terms and Conditions
          </Link>
        </Text>
      </Flex>

      <div style={{ marginLeft: '20px' }}>
        {nextClicked && formik.errors.termsAndConditions && (
          <div style={{ color: theme.colors.red['500'], fontSize: '14px' }}>
            {formik.errors.termsAndConditions}
          </div>
        )}
      </div>

      <div style={{ height: '20px' }}></div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button colorScheme="blue" mr={3} type="button" onClick={handleSubmit} disabled={!formik.isValid}>
          Next
        </Button>
      </div>
      <TermsAndConditionsModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </form>
  );
};

export default OtherInfo;
