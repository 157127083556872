import { 
  Select, 
  FormControl, 
  FormLabel, 
  FormErrorMessage, 
  Flex,
  useTheme
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPlanId, setSelectedPlanCode } from '../../../features/registration/registrationSlice';

const SubscriptionType = ({ formik, nextClicked }) => {
  const REACT_APP_PAYPAL_PLAN_ID_1_MONTH = process.env.REACT_APP_PAYPAL_PLAN_ID_1_MONTH;
  const REACT_APP_PAYPAL_PLAN_ID_6_MONTH = process.env.REACT_APP_PAYPAL_PLAN_ID_6_MONTH;

  const dispatch = useDispatch();
  const theme = useTheme();

  const selectedPlanId = useSelector((state) => state.registration.value.selectedPlanId);
  const selectedPlanCode = useSelector((state) => state.registration.value.selectedPlanCode);

  useEffect(() => {
    // Trigger form validation when formik.values.planType changes
    formik.validateForm();
  }, [formik.values.planType]);

  useEffect(() => {
  }, [selectedPlanId, selectedPlanCode])

  const dispatchSelectedPlanCode = (selectedPlanId) => {
    let selectedPlanCode;
    switch(selectedPlanId){
      case 'P-5F469844H06454249MRYTDGA':
        selectedPlanCode = '1MONTH';
        break;
      case 'P-6G8965821G006683MMR4UPOI':
        selectedPlanCode = '6MONTH';
        break;
      default:
        break;
    }
    dispatch(setSelectedPlanCode(selectedPlanCode));
  }

  return (
    <FormControl
      id="planType"
      isInvalid={formik.errors.selectedPlanId && 
        (formik.touched.selectedPlanId || nextClicked)}>
      <FormLabel
        htmlFor='selectedPlanId'
        _invalid={{ color: theme.colors.red['500'] }}>
        Membership Plan
      </FormLabel>
      <Select
        name="selectedPlanId"
        value={selectedPlanId}
        onChange={(x) => {
          const selectedPlanId = x.target.value;
          dispatch(setSelectedPlanId(selectedPlanId));
          dispatchSelectedPlanCode(selectedPlanId);
          formik.setFieldValue('selectedPlanId', selectedPlanId);
        }}
        onBlur={formik.handleBlur}
      >
        <option value=''>Select a plan</option>
        <option value='P-5F469844H06454249MRYTDGA'>Monthly Membership ($4.99)</option>
        <option value='P-6G8965821G006683MMR4UPOI'>6-Month Membership ($19.99)</option>
      </Select>
      <FormErrorMessage>{formik.errors.selectedPlanId}</FormErrorMessage>
    </FormControl>
  );
};

export default SubscriptionType;
