import {useEffect} from 'react';
import {Text, Heading} from '@chakra-ui/react';

const RegisterSuccess = () => {

    useEffect(() => {
        // upon successful registration - all of the previous 
        // intermediate local storage variables should be deleted
        
        localStorage.removeItem('ajn-number-of-trial-questions-asked');
        localStorage.removeItem('ajn-email-registration-email-confirmed-date');
        localStorage.removeItem('ajn-registration-email-sent'); // previous name
        localStorage.removeItem('ajn-email-registration-email-sent');
    }, [])

    // clear sessionStorage vars used for registration since they are no longer needed
    const clearSessionStorage = () => {
        sessionStorage.removeItem('registration.firstName');
        sessionStorage.removeItem('registration.lastName');
        sessionStorage.removeItem('registration.emailAddress');
        sessionStorage.removeItem('registration.password');
        sessionStorage.removeItem('registration.charityOfChoice');
        sessionStorage.removeItem('registration.selectedPlanId');
        sessionStorage.removeItem('registration.selectedPlanCode');
    }

    return (
        <>
            {/* spacer */}
            <div style={{height:80}}></div>

            <Heading as="h1" size="xl">Registration Success</Heading>

            <div style={{height:'30px'}}></div>

            <Text>Your membership registration for AskJesusNow.chat was successful!</Text>
            <Text>Please check your Inbox to Confirm your email address.</Text>
        </>
    )
}

export default RegisterSuccess;
