import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AndrewCiccarelliPortfolio from './components/andrew-ciccarelli-portfolio/andrew-ciccarelli-portfolio';
import AjnCommonLayoutRoutes  from './components/ajn-common-layout-routes/ajn-common-layout-routes';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<AjnCommonLayoutRoutes />} />
        <Route path="/andrew-ciccarelli-portfolio" element={<AndrewCiccarelliPortfolio />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
