import ChatCompletionsApi from '../../api/chat-completions-api';
import UserApi from '../../api/user-api';
import SessionChecker from '../../utility/session-checker';
import { useState, useRef, useEffect, useCallback } from 'react';
import './chat-bubbles.css';
import typingTexting from './typing-texting.gif';
import { 
  setMembershipPromptCanceled,
  //setUserOkdCheckInboxMessage,
  //setEmailPromptCanceled,
  //incrementNumberOfTrialQuestionsAsked,
  //setEmailAddressSubmitted,
 } from '../../features/userTrial/userTrialSlice';
import EmailRegistrationModal from '../email-registration-modal/email-registration-modal';
import { 
  Heading, 
  Text, 
  Box,
  Link as ChakraLink,
  VStack,
  Flex
} from '@chakra-ui/react';
import AIJesusDisclaimerModal from '../ai-jesus-disclaimer-modal/ai-jesus-disclaimer-modal';
import MembershipRequiredModal from '../membership-required-modal/membership-required-modal';
import EmailRegistrationConfirmationModal from '../email-registration-confirmation-modal/email-registration-confirmation-modal';
import { useNavigate, Link } from 'react-router-dom';
import TextArea from './text-area';
import { useSelector, useDispatch } from 'react-redux';
import MembershipStateManager from '../../utility/membership-state-manager';
import shareIcon from './share-icon.png';
import { InfoIcon } from '@chakra-ui/icons';


const Home = () => {

    /* INIT HOOKS */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /* USESELECTORS */
    const isPaidUser = useSelector(
      state => state.membership.value.isPaidUser);

    const isAnonymousUser = useSelector(
      state => state.membership.value.isAnonymousUser);

    const isEmailOnlyUser = useSelector(
      state => state.membership.value.isEmailOnlyUser);

    // const emailRegistrationEmailSent = useSelector(
    //   state => state.membership.value.emailRegistrationEmailSent);

    const emailRegistrationEmailSent = localStorage.getItem('ajn-email-registration-email-sent')

    // const emailRegistrationEmailConfirmed = useSelector(
    //   state => state.membership.value.emailRegistrationEmailConfirmed);

    const emailRegistrationEmailConfirmed = localStorage.getItem(
      'ajn-email-registration-email-confirmed-date');

    const emailRegistrationEmailConfirmedDate = useSelector(
      state => state.membership.value.emailRegistrationEmailConfirmedDate);

    const numberOfTrialQuestionsAsked = useSelector(
      state => state.membership.value.numberOfTrialQuestionsAsked);

    // const userOkdCheckInboxMessage = useSelector(
    //   state => state.userTrial.value.userOkdCheckInboxMessage);

    const emailRegistratonEmailAddressSubmitted = emailRegistrationEmailSent;

    /* ENVIRONMENT VARIABLES */
    const FREE_ANONYMOUS_QUESTION_LIMIT = parseInt(process.env.REACT_APP_FREE_ANONYMOUS_QUESTION_LIMIT);
    const FREE_TRIAL_PERIOD_DAYS = parseInt(process.env.REACT_APP_FREE_TRIAL_PERIOD_DAYS);

    /* USESTATE VARIABLES */
    const [conversationHistory, setConversationHistory] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
    const [redisplayEmailPrompt, setRedisplayEmailPrompt] = useState(false);
    const [chatErrorOccured, setChatErrorOccured] = useState(false);
    const [userIPAddress, setUserIPAddress] = useState('');
    const [userEmailAddress, setUserEmailAddress] = useState('');
    const [openEmailRegistrationModal, setOpenEmailRegistrationModal] = useState(false);
    const [openMembershipRequiredModal, setOpenMembershipRequiredModal] = useState(false);

    const [openDisclaimerModal, setOpenDisclaimerModal] = useState(false);

    const [emailRegistrationModalCanceled, setEmailRegistrationModalCanceled] = useState(false);
    const [emailRegistrationModalSubmitted, setEmailRegistrationModalSubmitted] = useState(false);
    const [
      emailRegistrationConfirmationModalCanceled,
      setEmailRegistrationConfirmationModalCanceled
    ] = useState(false);
    const [userOkdCheckInboxMessage, setUserOkdCheckInboxMessage] = useState(false);
    const [isEmailRegistrationConfirmedUser, setIsEmailRegistrationConfirmedUser] = useState(false);

    //const [emailAddressSubmitted, setEmailAddressSubmitted] = useState(false);

    /* USEREFS */
    const scrollableDivRef = useRef(null);

    useEffect(() => {

        // init MembershipStateManager
        const msm = new MembershipStateManager(dispatch);

        // perform initial membership state cleanup as needed
        msm.syncLocalStorageAndState();

        const userEmailAddress2 = localStorage.getItem('ajn-user-email-address');
        let registeredUser;
        async function fetchData() {

          /* TODO: revisit
          // only need to check this conidition for update if not already true
          if(!isEmailRegistrationConfirmedUser){
            registeredUser = await msm.getUserFromDB(userEmailAddress2);

            if(registeredUser.EmailRegistrationConfirmedDate)
              setIsEmailRegistrationConfirmedUser(true);
          }
          */
        }
        fetchData();

        //scroll to top on init load
        window.scrollTo(0, 0);

        // get user ip address and set to local state
        new UserApi().getUserIPAddress()
          .then(result => {
            setUserIPAddress(result);
          })
          .catch(error => {
            console.log(error);
          });

        // get user email address and set to local state
        const userEmailAddress = localStorage.getItem('ajn-user-email-address');
        setUserEmailAddress(userEmailAddress);

      }, []);

      const cancelEmailRegistrationModal = () => {
        setEmailRegistrationModalCanceled(true);
      }

      const closeEmailRegistrationConfirmationModal = () => {
        setEmailRegistrationConfirmationModalCanceled(true);
      }

      const evalOpenEmailRegistrationPrompt = () => {
 
        // only display the modal for anonymous users
        if(!isAnonymousUser) return false;
  
        const openEmailPrompt =
          isAnonymousUser &&
          (numberOfTrialQuestionsAsked > FREE_ANONYMOUS_QUESTION_LIMIT) &&
            !userOkdCheckInboxMessage
  
        return openEmailPrompt || redisplayEmailPrompt;
      }

      useEffect(() => {

        // if number exceeds threshold - open email prompt
        setOpenEmailRegistrationModal(evalOpenEmailRegistrationPrompt());

      }, [numberOfTrialQuestionsAsked]);

      useEffect(() => {
        const lastIndex = conversationHistory.length - 1;
        const lastSequence = conversationHistory[lastIndex]?.sequence;
        scrollToChatBubble(lastSequence);
        if(lastSequence % 2 === 0) setIsProcessing(true)
        else {
            setIsProcessing(false);
        }
      }, [conversationHistory]);

      useEffect(() => {
        if (shouldScrollToBottom) {
          setTimeout(() => {
            scrollToBottom();
          }, 500);
          setShouldScrollToBottom(false);
        }
      }, [shouldScrollToBottom]);
      
      useEffect(() => {
        if (isProcessing) {
          setShouldScrollToBottom(true);
        }
      }, [isProcessing]);

      const scrollToBottom = () => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
      }

      const onMembershipPromptCanceled = () => {
        setOpenMembershipRequiredModal(false);
      }

      const onDisclaimerPromptClose = () => {
        setOpenDisclaimerModal(false);
      }

      const scrollToChatBubble = (index) => {
        const chatBubbleId = 'chat-bubble-' + index;
        const chatBubbleElement = document.getElementById(chatBubbleId);
        const grandparentDiv = document.getElementById('grandparent-div');
      
        if (chatBubbleElement && grandparentDiv) {
          const bubbleSenderElements = grandparentDiv.getElementsByClassName('bubble-sender');
      
          if (bubbleSenderElements.length > 0) {
            const lastBubbleSenderElement = bubbleSenderElements[bubbleSenderElements.length - 1];
            const scrollPosition = lastBubbleSenderElement.offsetTop - grandparentDiv.offsetTop - 3;
            grandparentDiv.scrollTop = scrollPosition;
          } else {
            grandparentDiv.scrollTop = chatBubbleElement.offsetTop;
          }
        }
      };

    const userShouldBePrompted = async () => {

      if (emailRegistrationEmailSent && !emailRegistrationEmailConfirmed){
        // trigger redisplay of confirm email modal
        setUserOkdCheckInboxMessage(false);
        return true;
      }else if(isAnonymousUser){
        if(numberOfTrialQuestionsAsked >= FREE_ANONYMOUS_QUESTION_LIMIT){
          // reset cancel toggle if set
          setEmailRegistrationModalCanceled(false);
          setOpenEmailRegistrationModal(true);
          return true;
        }
      } else if(isEmailOnlyUser) {
        if(hasFreeTrialExpired()){
          setOpenMembershipRequiredModal(true);
          setMembershipPromptCanceled(false);
          return true;
        }
      }
      return false;
    }

    const hasFreeTrialExpired = () => {
      const freeTrialExpirationDate = new Date(emailRegistrationEmailConfirmedDate);
      freeTrialExpirationDate.setDate(freeTrialExpirationDate.getDate() + FREE_TRIAL_PERIOD_DAYS);
      const freeTrialExpired = freeTrialExpirationDate < new Date();
      return freeTrialExpired;
    }
        
    const askQuestion = async (question) => {

      //debugger;

      // if no question then return
      if(!question) return;

      /*
      let isValid = false;
      if(isPaidUser) isValid = await askQuestionPaidUserValidate();
      else isValid = await askQuestionUnpaidUserValidate();
      if(isValid) await askQuestionImplementation(question);
      */
     await askQuestionImplementation(question);
    }

    const askQuestionUnpaidUserValidate = async (question) => {

      const emailRegistrationEmailSent2 = 
        localStorage.getItem('ajn-email-registration-email-sent') === true

      // if email is sent but not confirmed
      if(emailRegistrationEmailSent2 && !emailRegistrationEmailConfirmed){

        // redisplay the modal to the user to confirm email
        //dispatch(setUserOkdCheckInboxMessage(false));
        setUserOkdCheckInboxMessage(false);
        return false;
      }

      // if user should be prompted then return
      if(await userShouldBePrompted()) return false;

      // else sync question count and state
      const membershipStateManager = new MembershipStateManager(dispatch);
      membershipStateManager.incrementTrialQuestionsCount();
      membershipStateManager.syncLocalStorageAndState();

      // it is valid to continue the question flow
      return true;
    }

    const askQuestionPaidUserValidate = () => {

      // session is valid if < 120 minutes old
      const sessionIsValid = new SessionChecker().sessionIsValid(120);

      // if session is not valid then navigate to login page
      if(!sessionIsValid){
        navigate('/login');
        return;
      }else{
        // else sync token to current time
        new MembershipStateManager(dispatch).refreshTokenTimeout();
      }
    }
    
    const askQuestionImplementation = async (question) => {
      setConversationHistory((prevHistory) => {
        const updatedHistory = [
          ...prevHistory,
          {
            sequence: prevHistory.length,
            type: 'question',
            text: question,
          },
        ];
        return updatedHistory;
      });

      setIsProcessing(true);
    
      let answer = await new ChatCompletionsApi().submit(
        question, userEmailAddress, userIPAddress);

      // hack to handle a recurring issue - 1 retry typically fixes
      if(answer.message){
        answer = await new ChatCompletionsApi().submit(
          question, userEmailAddress, userIPAddress);
      }

      if(answer.error){
        setChatErrorOccured(true);
        setIsProcessing(false);
        return;
      }else{
        setChatErrorOccured(false);
      }

      setConversationHistory((prevHistory) => {
        const updatedHistory = [
          ...prevHistory,
          {
            sequence: prevHistory.length,
            type: 'answer',
            text: answer,
          },
        ];
        return updatedHistory;
      });
    };

    /*
    if(!emailRegistrationEmailConfirmed){
      debugger;
    }
    */

    return (
      <Box lineHeight="40px">
  
        <Box mt="85" mb={8}>
          <Heading as="h1" size="xl">
            Home
          </Heading>
        </Box>
    
        <Box mx="3">
          <Text color="black" style={{ lineHeight: '20px' }} mb="3">
            AskJesusNow.chat uses Artificial Intelligence to simulate a conversation with Jesus 
            <InfoIcon
              onClick={() => { 
                // alert('info icon clicked!') 
                setOpenDisclaimerModal(true)
              }}
              mb="1"
              ml="1"
              boxSize={3} 
              color={'#1A74F4'} 
              onMouseLeave={() => document.body.style.cursor = 'default'}
              onMouseEnter={() => document.body.style.cursor = 'pointer'}
              /> 
            <br />
          </Text>
          <Text 
            mb="3.5" 
            style={{ lineHeight: '20px' }}>
            May all of your prayers be answered
          </Text>
        </Box>
    
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            id="grandparent-div"
            ref={scrollableDivRef}
            mx={3}
            style={{
              width: '100%',
              maxWidth: 500,
              minWidth: 350,
              maxHeight: 250,
              display: conversationHistory.length > 0 ? 'flex' : 'none',
              flexDirection: 'column',
              padding: 10,
              paddingTop: 1,
              overflowX: 'hidden',
              overflowY: 'auto',
              backgroundColor: 'white',
            }}
          >
            {Array.isArray(conversationHistory) &&
              conversationHistory?.map((x, index, conversationHistory) => (
                <>
                  {/* QUESTION/ANSWER BUBBLES */}
                  <Box
                    key={index}
                    style={{
                      marginTop: 20,
                      alignSelf: x.type === 'question' ? 'flex-end' : 'flex-start',
                      color: 'white',
                    }}
                    id="parent-div"
                  >
                    <Box
                      id={'chat-bubble-' + index}
                      className={x.type === 'question' ? 'bubble-sender' : 'bubble-recipient'}
                      style={{ textAlign: 'left' }}
                    >
                      {x.text}
                    </Box>
                  </Box>
    
                  {/* PROCESSING BUBBLE */}
                  <Box>
                    {isProcessing &&
                      index === conversationHistory.length - 1 &&
                      conversationHistory &&
                      conversationHistory[conversationHistory.length - 1].type !== 'answer' ? (
                      <div style={{ marginTop: 20, position: 'relative', left: 0 }}>
                        <img src={typingTexting} style={{ height: '20%', width: '20%' }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </Box>
                </>
              ))}
          </Box>
        </Box>
    
        <Flex 
          align="center" 
          justify="center">
          <Box 
            width='100%'
            maxWidth={500} 
            minWidth={350} 
            my="5"
            mx={3}>
            <TextArea askQuestion={askQuestion} />
          </Box>
        </Flex>

        <Flex
          align="center" 
          justify="center">
        </Flex>
    
        {chatErrorOccured && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Text color="red">A chat error occurred. Please try again.</Text>
          </Box>
        )}
    
        {/*
        {!isPaidUser && (
          <VStack spacing={0} alignItems="center" mt="3" mb="6">
            <Text lineHeight="1.5">Not a member yet?</Text>
            <Text lineHeight="1.0">
              <ChakraLink as={Link} to="/register" textDecoration="underline" color="blue">
                Register here
              </ChakraLink>
            </Text>
          </VStack>
        )}
        */}

        {/* EMAIL REGISTRATION MODAL */}
        <div style={{  
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'}}>
            <EmailRegistrationModal 
              isOpen= { 
                openEmailRegistrationModal &&
                !emailRegistrationModalSubmitted &&
                !emailRegistrationModalCanceled 
              }
              onModalCancel={cancelEmailRegistrationModal}
              onModalSubmit={() => {
                setEmailRegistrationModalSubmitted(true)
                //new MembershipStateManager(dispatch).syncLocalStorageAndState();
                //setEmailRegistrationEmailSent(true);
              }}
            />
        </div>

        {/* EMAIL REGISTRATION *CONFIRMATION* MODAL */}
        <div style={{  
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'}}>
            <EmailRegistrationConfirmationModal 
              isOpen={
                  !emailRegistrationConfirmationModalCanceled &&
                  emailRegistrationEmailSent &&
                  !isEmailRegistrationConfirmedUser &&
                  !userOkdCheckInboxMessage
              }
              onModalClose={closeEmailRegistrationConfirmationModal}
              onModalOk={() => {setUserOkdCheckInboxMessage(true)}}
              emailConfirmed={emailRegistrationEmailConfirmed}
            />
        </div>
        
        {/* MEMBERSHIP REQUIRED MODAL */}
        <div style={{  
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'}}>
            <MembershipRequiredModal 
              isOpen={openMembershipRequiredModal}
              onModalCancel={onMembershipPromptCanceled}
            />
        </div>

        {/* AI JESUS DISCLAIMER MODAL */}
        <div style={{  
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'}}>
            <AIJesusDisclaimerModal 
              isOpen={openDisclaimerModal}
              onModalClose={onDisclaimerPromptClose}
              onModalOk={onDisclaimerPromptClose}
            />
        </div>

  </Box>)
}

export default Home;
