import {
    Heading,
    Box,
    Input,
    FormControl,
    FormLabel,
    Button,
    FormErrorMessage,
    useTheme
  } from '@chakra-ui/react';
  import { useFormik } from 'formik';
  import React, { useState, useRef, useEffect } from 'react';
  import * as Yup from 'yup';
  
  const SiteAdmin = () => {
    const theme = useTheme();
  
    const validationSchema = Yup.object({
      //firstName: Yup.string()
      //  .required('First Name is required'),
    });
  
    const formik = useFormik({
      initialValues: {
        //   firstName: firstName,
        //   lastName: lastName,
        //   emailAddress: emailAddress,
        //   password: password,
      },
      validationSchema,
      onSubmit: async (values) => {
        //dispatch(setAccordionItemIndex(1));
      },
    });
  
    const fileUploadRef = useRef(null);
  
    useEffect(() => {
      if (fileUploadRef.current) {
        fileUploadRef.current.focus();
      }
    }, []);
  
    return (
      <Box lineHeight="40px" textAlign="center">
        <Box mt="85" mb={1}>
          <Heading as="h1" size="xl">
            Site Config
          </Heading>
        </Box>
  
        <Box display="flex" justifyContent="center">
          <Box width={'100%'} maxWidth={'500px'} minWidth={'350px'}>
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                isInvalid={formik.touched.fileUpload && formik.errors.fileUpload}
                mt={4}
              >
                <FormLabel
                  htmlFor="fileUpload"
                  _invalid={{ color: theme.colors.red['500'] }}
                >
                  File Upload
                </FormLabel>
                <Input
                  id="fileUpload"
                  placeholder="File Upload"
                  ref={fileUploadRef}
                  backgroundColor="white"
                  value={formik.values.firstName}
                  onChange={(x) => {
                    //dispatch(setPersonalInfoKeyValue({key:'firstName', value: x.target.value}));
                    formik.setFieldValue('fileUpload', x.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.fileUpload && (
                  <FormErrorMessage>{formik.errors.fileUpload}</FormErrorMessage>
                )}
              </FormControl>
            </form>
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default SiteAdmin;
  