import {
    Box,
    Heading,
  } from '@chakra-ui/react';
  import {
    Accordion,
    AccordionIcon,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Text,
    useTheme,
  } from "@chakra-ui/react";
  import React, { useState, useRef, useEffect } from 'react';
  import PersonalInfo from './personal-info/personal-info';
  import CharityOfChoice from './charity-of-choice/charity-of-choice';
  import './accordion.css';
  import { useSelector, useDispatch } from 'react-redux'
  import {
    setPersonalInfo,
    setCharityOfChoice,
    setAccordionItemIndex,
    setPaymentMade,
    setForceRefresh,
  } from '../../features/registration/registrationSlice';
  import PayPalCheckout from './paypal-checkout/paypal-checkout';
  import { Link } from 'react-router-dom';
  import OtherInfo from './other-info/other-info';
  
  const Register = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const personalInfo = useSelector(state => state.registration.value.personalInfo);
  
    //const charityOfChoice = useSelector(state => state.registration.value.charityOfChoice);
    //const termsAndConditions = useSelector(state => state.registration.value.termsAndConditions);
  
    /* SELECTORS - FOR PAYPAL */
    const firstName = useSelector(state => state.registration.value.personalInfo.firstName);
    const lastName = useSelector(state => state.registration.value.personalInfo.lastName);
    const emailAddress = useSelector(state => state.registration.value.personalInfo.emailAddress);
    const password = useSelector(state => state.registration.value.personalInfo.password);
    const charityOfChoice = useSelector(state => state.registration.value.charityOfChoice);
    const termsAndConditions = useSelector(state => state.registration.value.termsAndConditions);
    const selectedPlanId = useSelector(state => state.registration.value.selectedPlanId);
    const selectedPlanCode = useSelector(state => state.registration.value.selectedPlanCode);
  
    const forceRefresh = useSelector(state => state.registration.value.forceRefresh);
  
    const [dependenciesResolved, setDependenciesResolved] = useState(false);
  
    const accordionItemIndex = useSelector(
      state => state.registration.value.accordionItemIndex);
  
    const billingInfoDependenciesResolved = useSelector(
      state => state.registration.value.billingInfoDependenciesResolved);
  
    const handleAccordionChange = (index) => {
      dispatch(setAccordionItemIndex(index));
    };
  
    const validateDependenciesResolved = () => {
  
      // "user info" accordionitem
      const isPersonalInfoValid =
        !!personalInfo.firstName &&
        !!personalInfo.lastName &&
        !!personalInfo.emailAddress &&
        !!personalInfo.password;
      if (!isPersonalInfoValid) {
        setAccordionItemIndex(0);
        if(dependenciesResolved) setDependenciesResolved(false);
        return;
      }
  
      // "charity of choice" accordionitem
      const isCharityOfChoiceValid = !!charityOfChoice;
      if (!isCharityOfChoiceValid) {
        setAccordionItemIndex(1);
        if(dependenciesResolved) setDependenciesResolved(false);
        return;
      }
  
      // "other info" accordionitem
      const termsAndConditionsIsValid = !!termsAndConditions;
      const selectedPlanIdIsValid = !!selectedPlanId;
      const otherInfoIsValid = termsAndConditionsIsValid && selectedPlanIdIsValid;
      if (!otherInfoIsValid) {
        setAccordionItemIndex(2);
        if(dependenciesResolved) setDependenciesResolved(false);
        return;
      }

      debugger;
  
      // if all dependencies are resolved then enable billing info accordionitem
      if(!dependenciesResolved){
        setDependenciesResolved(true);
      }
      
      // if all dependencies are resolved then expand billing info accordionitem
      if(dependenciesResolved && accordionItemIndex !== 2) setAccordionItemIndex(2);
    }
  
    useEffect(() => {
      validateDependenciesResolved();
    }, [])
  
    const finalizeRegistration = () => {
  
      // this should refresh the useSelectors 
      // so the user object gets populated below
      // setDependenciesResolved(true);
      dispatch(setForceRefresh('' + Date.now()));
  
      // check global state vars here
      const user = {
        firstName,
        lastName,
        emailAddress,
        termsAndConditions,
        charityOfChoice
      }
    }

    validateDependenciesResolved();
  
    return (
      <Box width="100%" display="flex" justifyContent="center" minWidth="320px">
        <Box maxWidth="750px" width="90%" mt="50px">
          <Box mt={8} mb={8}>
            <Heading as="h1" size="xl">
              Register
            </Heading>
          </Box>
  
          <Accordion 
            allowToggle 
            mb={3} 
            index={accordionItemIndex} 
            defaultIndex={0} 
            onChange={handleAccordionChange}>
            <AccordionItem>
              <h2>
                <AccordionButton borderTop='1px solid lightgrey'>
                  <Box flex="1" textAlign="center">
                    <Heading as="h2" size="md">User Info</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel backgroundColor='white' p={0}>
                <Box p={4}>
                  <PersonalInfo />
                </Box>
              </AccordionPanel>
            </AccordionItem>
  
            <AccordionItem>
              <h2>
                <AccordionButton borderTop='1px solid lightgrey'>
                  <Box flex="1" textAlign="center">
                    <Heading as="h2" size="md">Charity of Choice</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel backgroundColor='white' p={0}>
                <Box p={4}>
                  <CharityOfChoice />
                </Box>
              </AccordionPanel>
            </AccordionItem>
  
            <AccordionItem>
              <h2>
                <AccordionButton borderTop='1px solid lightgrey'>
                  <Box flex="1" textAlign="center">
                    <Heading as="h2" size="md">Other Info</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel backgroundColor='white' p={0}>
                <Box p={4}>
                  <OtherInfo />
                </Box>
              </AccordionPanel>
            </AccordionItem>
  
            <AccordionItem isDisabled={!dependenciesResolved}>
              <h2>
                <AccordionButton borderTop='1px solid lightgrey' borderBottom='1px solid lightgrey'>
                  <Box flex="1" textAlign="center">
                    <Heading as="h2" size="md">Billing Info</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel backgroundColor='white' p={0}>
                <PayPalCheckout
                  firstName={firstName}
                  lastName={lastName}
                  emailAddress={emailAddress}
                  password={password}
                  charityOfChoice={charityOfChoice}
                  termsAndConditions={termsAndConditions}
                  selectedPlanId={selectedPlanId}
                  selectedPlanCode={selectedPlanCode}
                  registerCallbackHandler={finalizeRegistration}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Box mb="8">
            {!dependenciesResolved &&
              <Text
                style={{ color: theme.colors.red['500'] }}
              >
                Please complete the input fields in the first 3 Accordion Items before entering Billing Info
              </Text>
            }

            {dependenciesResolved &&
              <Text
                style={{ color: theme.colors.red['500'] }}
              >
                Please Enter Billing Info in the last Accordion Item to complete transaction
              </Text>
            }
          </Box>

        </Box>
      </Box>
    );
  }
  
  export default Register;
  