import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Box } from '@chakra-ui/react';

const TermsAndConditionsModal = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent marginTop="150px">
          <ModalHeader>Terms and Conditions</ModalHeader>
          <ModalBody>
            <Box height="300px" overflowY="scroll">
              <Text fontSize="md" lineHeight="tall">
                <Text fontWeight="bold" marginBottom="2">Terms and Conditions</Text>
                <Text>
                  These terms and conditions ("Agreement") govern your use of AskJesusNow.chat ("Company") and any related services, products, or content provided by the Company. By accessing or using our services, you agree to be bound by this Agreement. If you do not agree to these terms, please refrain from using our services.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">1. Acceptance of Terms</Text>
                <Text>
                  1.1. By using our services, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this Agreement.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">2. Use of Services</Text>
                <Text>
                  2.1. You agree to use our services only for lawful purposes and in compliance with all applicable laws and regulations.
                </Text>
                <Text>
                  2.2. You are responsible for maintaining the confidentiality of your account credentials and are solely responsible for all activities that occur under your account.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">3. Intellectual Property</Text>
                <Text>
                  3.1. All content, including but not limited to text, images, graphics, logos, and software, available on our website or provided through our services is the intellectual property of the Company and is protected by applicable copyright, trademark, and other intellectual property laws.
                </Text>
                <Text>
                  3.2. You may not copy, reproduce, modify, distribute, or create derivative works of any content without prior written permission from the Company.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">4. Privacy and Data Collection</Text>
                <Text>
                  4.1. The Company respects your privacy and handles your personal information in accordance with our Privacy Policy. By using our services, you consent to the collection, use, and disclosure of your personal information as outlined in the Privacy Policy.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">5. Disclaimer of Warranties</Text>
                <Text>
                  5.1. Our services are provided on an "as is" and "as available" basis without any warranties, either expressed or implied.
                </Text>
                <Text>
                  5.2. The Company does not guarantee the accuracy, completeness, or reliability of any content or information provided through our services.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">6. Limitation of Liability</Text>
                <Text>
                  6.1. To the fullest extent permitted by law, the Company and its officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, data, or other intangible losses, arising out of or in connection with your use or inability to use our services.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">7. Indemnification</Text>
                <Text>
                  7.1. You agree to indemnify and hold the Company and its officers, directors, employees, and agents harmless from any claims, damages, losses, liabilities, costs, or expenses arising out of or in connection with your use of our services or violation of this Agreement.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">8. Modifications to the Agreement</Text>
                <Text>
                  8.1. The Company reserves the right to modify or update this Agreement at any time without prior notice. Any changes will be effective immediately upon posting the revised Agreement on our website. It is your responsibility to review this Agreement periodically to stay informed of any updates.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">9. Governing Law and Jurisdiction</Text>
                <Text>
                  9.1. This Agreement shall be governed by and construed in accordance with the laws of Las Vegas, NV USA. Any legal action or proceeding arising out of or relating to this Agreement shall be exclusively subject to the jurisdiction of the courts in Las Vegas, NV USA.
                </Text>

                <Text fontWeight="bold" marginTop="4" marginBottom="2">10. Severability</Text>
                <Text>
                  10.1. If any provision of this Agreement is held to be invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and effect.
                </Text>
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TermsAndConditionsModal;
