import React from 'react';
import axios from 'axios';

class LoginApi extends React.Component {
    static API_ENVIRONMENT_URL = process.env.REACT_APP_API_ENVIRONMENT_URL;

    async submit(userId, password){

        const postBody = {
            userId,
            password
        };
        
        try {
            const loginApi = `${LoginApi.API_ENVIRONMENT_URL}/api/auth/authenticate`;
            const response = await axios.post(loginApi, postBody);
            return response.data;
        } catch (error) {
            console.log(error);
            return 0;
        }
    }
}

export default LoginApi;
