import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { 
  Button, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton, 
  Input,
  Text,
  Box,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EmailRegistrationModalApi from '../../api/email-registration-modal-api';

const EmailRegistrationModal = ({ isOpen, onModalCancel, onModalSubmit }) => {
  const FREE_TRIAL_PERIOD_DAYS = parseInt(process.env.REACT_APP_FREE_TRIAL_PERIOD_DAYS);

  const inputRef = useRef();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // api call to persist email address and send confirmation email
      new EmailRegistrationModalApi().submit(formik.values.email);

      onModalSubmit();

      formik.resetForm();
    },
    validateOnChange: false,
    validateOnBlur: false,
  });
  
  return (
    <div>
      <Modal 
        isOpen={isOpen} 
        size="md" 
        initialFocusRef={inputRef} 
        onClose={() => {onModalCancel()}}>
        <ModalOverlay />
        <ModalContent
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="40vh"
          marginTop="100px"
        >
          <ModalHeader>Email registration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Submit your email address for a free {FREE_TRIAL_PERIOD_DAYS}-day trial</Text>
            <div style={{ height: 15 }}></div>
            <form onSubmit={formik.handleSubmit}>
              <Input
                ref={inputRef}
                placeholder="Enter your email address here"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                isInvalid={formik.touched.email && formik.errors.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div style={{ color: 'red' }}>{formik.errors.email}</div>
              )}

              <Box marginTop={3} marginBottom={3}>
                <Text>No credit card is required</Text>
              </Box>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                  Submit
                </Button>
                <Button variant="ghost" onClick={() => {onModalCancel()}} data-parsley-excluded>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

EmailRegistrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalCancel: PropTypes.func.isRequired,
  onModalSubmit: PropTypes.func.isRequired
};

export default EmailRegistrationModal;
