import { 
  Heading, 
  Text, 
  Button, 
  Input, 
  FormLabel, 
  FormControl, 
  Box, 
  VStack, 
  Link as ChakraLink,
  useTheme
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoginApi from '../../api/login-api';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [invalidCredentialsSubmitted, setInvalidCredentialsSubmitted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const theme = useTheme();

  const emailRef = useRef(null);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      //.matches(
      //  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
      //  'Password must be at least 8 characters long and include a number and a special character'
      //),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {

      setProcessing(true);

      // #1 - call the login api
      const response = await new LoginApi().submit(
        values.email, 
        values.password);

      // #2 - if successful then redirect to home page
      const authToken = response.authToken ;
      if (authToken > 0) {
        localStorage.setItem('ajn-user-auth-token', authToken);
        navigate('/home');
      } else {
        setInvalidCredentialsSubmitted(true);
      }

      setProcessing(false);

      // Reset form after submission
      resetForm();
    },
  });

  const handleChange = (event) => {
    formik.handleChange(event);
    if(invalidCredentialsSubmitted) setInvalidCredentialsSubmitted(false);
  };

  return (
    <div style={{ 
      lineHeight: '40px', 
      maxWidth: '50%', 
      margin: '0 auto',
      }}>

      <Box mt="85" mb="8">
        <Heading as="h1" size="xl">
          Login
        </Heading>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box maxWidth='500px' minWidth='300px'>

          <FormControl>
              <FormLabel
                htmlFor="email"
                _invalid={{ color: theme.colors.red['500'] }}
                color={formik.errors.email && formik.touched.email ? theme.colors.red['500'] : 'inherit'} // Add this line
              >
                Email
              </FormLabel>
              <Input
                id="email"
                type="email"
                name="email"
                placeholder="Email"
                backgroundColor="white"
                value={formik.values.email}
                onChange={handleChange}
                isInvalid={formik.touched.email && formik.errors.email}
                mb={2}
              />
              {formik.touched.email && formik.errors.email && (
                <Box maxWidth="300px" style={{ textAlign: 'left', display: 'block' }}>
                  <Text color="red" marginLeft={0} style={{ lineHeight: '25px' }}>
                    {formik.errors.email}
                  </Text>
                </Box>
              )}
            </FormControl>

            <FormControl>
              <FormLabel
                htmlFor="password"
                _invalid={{ color: theme.colors.red['500'] }}
                color={formik.errors.password && formik.touched.password ? theme.colors.red['500'] : 'inherit'} // Add this line
              >
                Password
              </FormLabel>
              <Input
                id="password"
                type="password"
                name="password"
                placeholder="Password"
                backgroundColor="white"
                value={formik.values.password}
                onChange={handleChange}
                isInvalid={formik.touched.password && formik.errors.password}
                mb={2}
              />
              {formik.touched.password && formik.errors.password && (
                <Box maxWidth="300px" style={{ textAlign: 'left', display: 'block' }}>
                  <Text color="red" marginLeft={0} style={{ lineHeight: '25px' }}>
                    {formik.errors.password}
                  </Text>
                </Box>
              )}
            </FormControl>

            {invalidCredentialsSubmitted && (
              <Text color="red" marginLeft={0} style={{ textAlign: 'left', display: 'block' }}>
                Invalid Email/Password credentials
              </Text>
            )}

            <Button 
              colorScheme="blue" 
              my={5}
              type="submit"
              isLoading={processing}
              >
                Submit
            </Button>
          </Box>
        </div>
      </form>

      <VStack spacing={3} alignItems="center">
        <VStack spacing={0} alignItems="center">
          <Text lineHeight="1.5">Not a member yet?</Text>
          <Text lineHeight="1.5">
          <ChakraLink as={Link} to="/register" textDecoration="underline" color="blue">
            Register here
          </ChakraLink>
          </Text>
        </VStack>
        <Text>
          <Link to="/forgot-password" style={{ textDecoration: 'underline', color: 'blue' }}>
            Forgot password
          </Link>
        </Text>
      </VStack>

    </div>
  );
};

export default Login;
