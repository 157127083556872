import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Heading, Text, AccordionIcon } from '@chakra-ui/react';
import React, { useState } from 'react';

const About = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  const isItemExpanded = (index) => {
    return index === expandedIndex;
  };

  return (
    <Box width="100%" display="flex" justifyContent="center" minWidth="320px">
      <Box maxWidth="750px" width="90%">

        <Box mt="85" mb="8">
          <Heading as="h1" size="xl">
            About
          </Heading>
        </Box>

        <Accordion allowToggle defaultIndex={0}>
          <AccordionItem isExpanded={isItemExpanded(0)}>
            {({ isExpanded }) => (
              <>
                <AccordionButton borderTop="1px solid lightgrey" onClick={() => handleAccordionChange(0)}>
                  <Box flex="1" textAlign="center">
                    <Heading as="h2" size="md">
                      Inspiration
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel backgroundColor="white">
                  <Box mx={4} my={2} textAlign="center">
                    <Text>
                      To serve those who are curious about chatting with an AI simulation of Jesus or looking for Christian guidance, comfort, or positive encouragement.
                    </Text>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          <AccordionItem isExpanded={isItemExpanded(1)}>
            {({ isExpanded }) => (
              <>
                <AccordionButton borderTop="1px solid lightgrey" onClick={() => handleAccordionChange(1)}>
                  <Box flex="1" textAlign="center">
                    <Heading as="h2" size="md">
                      Capabilities
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel backgroundColor="white">
                  <Box mx={4} my={2} textAlign="center">
                    <Text>Just ask! AI Jesus will respond appropriately to any casual, philosophical, or theological questions or comments.</Text>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {/*
          <AccordionItem isExpanded={isItemExpanded(2)}>
            {({ isExpanded }) => (
              <>
                <AccordionButton borderTop="1px solid lightgrey" borderBottom="1px solid lightgrey" onClick={() => handleAccordionChange(2)}>
                  <Box flex="1" textAlign="center">
                    <Heading as="h2" size="md">
                      Giving Back
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel backgroundColor="white">
                  <Box mx={4} my={2} textAlign="center">
                    <Text>
                      AskJesusNow.chat <u>donates 20%</u> of its ad revenue to charity organizations focused on helping those in need.
                    </Text>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          */}

        </Accordion>
      </Box>
    </Box>
  );
};

export default About;
