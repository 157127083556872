import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  useTheme
} from '@chakra-ui/react';
import React, { useState, useRef, useEffect } from 'react';
import UserApi from '../../../api/user-api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  setBillingInfoDependenciesResolved,
  setPersonalInfo,
  setPersonalInfoKeyValue
} from '../../../features/registration/registrationSlice';
import { setAccordionItemIndex } from '../../../features/registration/registrationSlice';

const PersonalInfo = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const firstName = useSelector(state => state.registration.value.personalInfo.firstName);
  const lastName = useSelector(state => state.registration.value.personalInfo.lastName);
  const emailAddress = useSelector(state => state.registration.value.personalInfo.emailAddress);
  const password = useSelector(state => state.registration.value.personalInfo.password);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('First Name is required'),
    lastName: Yup.string()
      .required('Last Name is required'),
    emailAddress: Yup.string()
      .required('Email is required')
      .email('Invalid email address'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
        'Password must be at least 8 characters long and include a number and a special character'
      ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      password: password,
    },
    validationSchema,
    onSubmit: async (values) => {
      dispatch(setAccordionItemIndex(1));
    },
  });

  const firstNameRef = useRef(null);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box maxWidth="450px" width="100%">
        <form onSubmit={formik.handleSubmit}>
          <FormControl isInvalid={formik.touched.firstName && formik.errors.firstName} mt={4}>
            <FormLabel htmlFor="firstName" _invalid={{ color: theme.colors.red['500'] }}>
              First Name
            </FormLabel>
            <Input
              id="firstName"
              placeholder='First Name'
              ref={firstNameRef}
              backgroundColor="white"
              value={formik.values.firstName}
              onChange={(x) => {
                dispatch(setPersonalInfoKeyValue({key:'firstName', value: x.target.value}));
                formik.setFieldValue('firstName', x.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName && (
              <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={formik.touched.lastName && formik.errors.lastName} mt={4}>
            <FormLabel htmlFor="lastName" _invalid={{ color: theme.colors.red['500'] }}>
              Last Name
            </FormLabel>
            <Input
              id="lastName"
              placeholder='Last Name'
              backgroundColor="white"
              value={formik.values.lastName}
              onChange={(x) => {
                dispatch(setPersonalInfoKeyValue({key:'lastName', value: x.target.value}));
                formik.setFieldValue('lastName', x.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={formik.touched.emailAddress && formik.errors.emailAddress} mt={4}>
            <FormLabel htmlFor="emailAddress" _invalid={{ color: theme.colors.red['500'] }}>
              Email Address
            </FormLabel>
            <Input
              id="emailAddress"
              placeholder='Email Address'
              backgroundColor="white"
              value={formik.values.emailAddress}
              onChange={(x) => {
                dispatch(setPersonalInfoKeyValue({key:'emailAddress', value: x.target.value}));
                formik.setFieldValue('emailAddress', x.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.emailAddress && formik.errors.emailAddress && (
              <FormErrorMessage>{formik.errors.emailAddress}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={formik.touched.password && formik.errors.password} mt={4}>
            <FormLabel htmlFor="password" _invalid={{ color: theme.colors.red['500'] }}>
              Password
            </FormLabel>
            <Input
              id="password"
              type="password"
              placeholder='Password'
              backgroundColor="white"
              value={formik.values.password}
              onChange={(x) => {
                dispatch(setPersonalInfoKeyValue({key:'password', value: x.target.value}));
                formik.setFieldValue('password', x.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password && (
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            )}
          </FormControl>

          <Button colorScheme="blue" mt={4} type="submit" onSubmit={formik.handleSubmit}>
            Next
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default PersonalInfo;
