import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // or any other storage engine you prefer
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import userTrialReducer from '../features/userTrial/userTrialSlice';
import registrationReducer from '../features/registration/registrationSlice';
import registrationElonReducer from '../features/elon/registration/registrationSlice';
import membershipReducer from '../features/membership/membershipSlice';

const persistConfig = {
    key: 'root',
    storage: storage,
  }

const rootReducer = combineReducers({
    userTrial: userTrialReducer,
    registration: registrationReducer,
    membership: membershipReducer,
    registrationElon: registrationElonReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    devTools: process.env.NODE_ENV !== 'production',
  });
  
export const persistor = persistStore(store);
