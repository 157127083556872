import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    isAnonymousUser: true,
    isPaidUser: false,
    isEmailOnlyUser: false,
    emailRegistrationEmailSent: false,
    emailRegistrationEmailConfirmed: false,
    emailRegistrationEmailConfirmedDate: null,
    emailRegistrationEmailSentButNotConfirmed: false,
    numberOfTrialQuestionsAsked: 0,
  }
}

export const membershipSlice = createSlice({
    name: 'membership',
    initialState,
    reducers: {
        setIsAnonymousUser: (state, action) => {
            state.value.isAnonymousUser = action.payload;
        },
        setIsPaidUser: (state, action) => {
            state.value.isPaidUser = action.payload;
        },
        setIsEmailOnlyUser: (state, action) => {
            state.value.isEmailOnlyUser = action.payload;
        },
        setEmailRegistrationEmailSent: (state, action) => {
            state.value.emailRegistrationEmailSent = action.payload;
        },
        setEmailRegistrationEmailConfirmed: (state, action) => {
            state.value.emailRegistrationEmailConfirmed = action.payload;
        },
        setEmailRegistrationEmailConfirmedDate: (state, action) => {
            state.value.emailRegistrationEmailConfirmedDate = action.payload;
        },
        setEmailRegistrationEmailSentButNotConfirmed: (state, action) => {
            state.value.emailRegistrationEmailSentButNotConfirmed = action.payload;
        },
        setNumberOfTrialQuestionsAsked: (state, action) => {
            state.value.numberOfTrialQuestionsAsked = action.payload;
        },
    }
})

// Action creators are generated for each case reducer function
export const { 
    setIsAnonymousUser,
    setIsPaidUser,
    setIsEmailOnlyUser,
    setEmailRegistrationEmailSent,
    setEmailRegistrationEmailConfirmed,
    setEmailRegistrationEmailConfirmedDate,
    setEmailRegistrationEmailSentButNotConfirmed,
    setNumberOfTrialQuestionsAsked
} = membershipSlice.actions

export default membershipSlice.reducer
