import logo from '../../logo.jpg';
import tinybubblelogo from '../../tiny-bubble-logo.png';
import '../../App.css';
import '../../toolbar.css';
import '../../landing-page.css';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { VStack, Box } from '@chakra-ui/react';
import { Link, Outlet } from 'react-router-dom';
/*
// ELON routes adjusted
import ElonLogin from '../elon/login/login';
import ElonRegister from '../elon/register/register';
import ElonHome from '../elon/home/home';
import ElonContact from '../elon/contact/contact';
import ElonAbout from '../elon/about/about';
import ElonForgotPassword from '../elon/forgot-password/forgot-password';
*/

export default function AjnCommonLayout() {
    const [cursorStyle, setCursorStyle] = useState('default');
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [activeLink, setActiveLink] = useState('');
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
  
    // let location = useLocation();
  
    //const isAnonymousUser = useSelector(state => 
    //  state?.persistedReducer?.userTrial?.value?.emailAddressSubmitted);
  
    //const isAnonymousUser = useSelector(state => 
    //  state.userTrial.value.emailAddressSubmitted);
  
    const handleMouseOver = (link, menuItem) => {
      setCursorStyle('pointer');
      setSelectedMenuItem(menuItem);
    };
  
    const handleMouseOut = () => {
      setCursorStyle('default');
      setSelectedMenuItem('');
    };
    
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth >= 600) {
          setDisplayMobileMenu(false);
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    let siteCode;
    const href =window.location.href;
    if(href.includes('elon')) siteCode = 'elon';
    else siteCode = 'jc';
  
    let siteTitle;
    switch(siteCode){
      case 'elon':
        siteTitle = 'AskElonMusk.chat';
        break;
      case 'jc':
        siteTitle = 'AskJesusNow.chat';
        break;
      default:
        break;
    }
  
    const baseUrl = '/' + (siteCode === 'elon' ? 'elon/' : '');
  
    const menuRoutes = {
      about: baseUrl + 'about',
      contact: baseUrl + 'contact',
      login: baseUrl + 'login',
      home: baseUrl + 'home',
      siteConfig: baseUrl + 'site-config',
    }
  
    return (
      <>
  
      <div className="App">
  
        <header id="fixed-header" style={{ backgroundColor: 'white' }}>
          <nav>
            {/* LEFT LOGO */}
            <div className="logo" style={{
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'left',
              paddingLeft: '15px',
              cursor: cursorStyle,
            }}
              onMouseOver={() => setCursorStyle('pointer')}
              onMouseOut={() => setCursorStyle('default')}
            >
  
                <a 
                  href={baseUrl}
                  style={{ 
                    height: '100%', 
                    width: '100%' 
                  }} 
                >
  
                {siteCode === 'elon' &&
                  <img 
                    src={tinybubblelogo}
                    style={{ 
                      //height: '40%', 
                      //width: '40%' 
                    }} 
                    alt="Logo" 
                    title={siteTitle}
                  />
                }
  
                {siteCode === 'jc' &&
                  <img 
                    src={logo}
                    style={{ 
                      height: '40%', 
                      width: '40%' 
                    }} 
                    alt="Logo" 
                    title={siteTitle}
                  />
                }
  
                </a>
            </div>
  
            {/* HEADER BAR MENU ITEMS */}
            <ul className="menu" id="menu">
            <li
              style={{
                fontWeight: 500,
                textDecoration: 'none',
                //borderBottom: activeLink === '/' ? '2px solid blue' : 'none',
                cursor: 'pointer',
                //marginTop: selectedMenuItem === 'Home' ? '-3px' : '0',
              }}
              onMouseOver={() => handleMouseOver('/', 'Home')}
              onMouseOut={handleMouseOut}
            >
                <VStack spacing={-3}>
                  <Link to={menuRoutes.home}>
                    <div style={{
                      color: '#1876f2', 
                      borderBottom: selectedMenuItem === 'Home' ? '4px solid #1876f2' : '', 
                      paddingBottom: selectedMenuItem === 'Home' ? '2px' : '6px'}}>Home</div>
                  </Link>
                </VStack>
            </li>
  {/*
            <li
              style={{
                fontWeight: 500,
                textDecoration: 'none',
                //borderBottom: activeLink === '/' ? '2px solid blue' : 'none',
                cursor: 'pointer',
                //marginTop: selectedMenuItem === 'Home' ? '-3px' : '0',
              }}
              onMouseOver={() => handleMouseOver('/site-config', 'SiteConfig')}
              onMouseOut={handleMouseOut}
            >
                <VStack spacing={-3}>
                  <Link to={menuRoutes.siteConfig}>
                    <div style={{
                      color: '#1876f2', 
                      borderBottom: selectedMenuItem === 'SiteConfig' ? '4px solid #1876f2' : '', 
                      paddingBottom: selectedMenuItem === 'SiteConfig' ? '2px' : '6px'}}>Site Config</div>
                  </Link>
                </VStack>
            </li>
  */}
              {/*
              <li
                style={{
                  fontWeight: 500,
                  textDecoration: 'none',
                  borderBottom: activeLink === '/login' ? '2px solid blue' : 'none',
                  cursor: 'pointer',
                }}
                onMouseOver={() => handleMouseOver('/login', 'Login')}
                onMouseOut={handleMouseOut}
              >
                <VStack spacing={-3}>
                  <Link to={menuRoutes.login}>
                    <div style={{
                      color: '#1876f2', 
                      borderBottom: selectedMenuItem === 'Login' ? '4px solid #1876f2' : '', 
                      paddingBottom: selectedMenuItem === 'Login' ? '2px' : '6px'}}>Login</div>
                  </Link>
                </VStack>
              </li>
              */}
  
              <li
                style={{
                  fontWeight: 500,
                  textDecoration: 'none',
                  borderBottom: activeLink === '/about' ? '2px solid blue' : 'none',
                  cursor: 'pointer',
                }}
                onMouseOver={() => handleMouseOver('/about', 'About')}
                onMouseOut={handleMouseOut}
              >
                <VStack spacing={-3}>
                  <Link to={menuRoutes.about}>
                    <div style={{
                      color: '#1876f2', 
                      borderBottom: selectedMenuItem === 'About' ? '4px solid #1876f2' : '', 
                      paddingBottom: selectedMenuItem === 'About' ? '2px' : '6px'}}>About</div>
                  </Link>
                </VStack>
              </li>
  
              <li
                style={{
                  fontWeight: 500,
                  textDecoration: 'none',
                  borderBottom: activeLink === '/contact' ? '2px solid blue' : 'none',
                  cursor: 'pointer',
                }}
                onMouseOver={() => handleMouseOver('/contact', 'Contact')}
                onMouseOut={handleMouseOut}
              >
                <VStack spacing={-3}>
                  <Link to={menuRoutes.contact}>
                    <div style={{
                      color: '#1876f2', 
                      borderBottom: selectedMenuItem === 'Contact' ? '4px solid #1876f2' : '', 
                      paddingBottom: selectedMenuItem === 'Contact' ? '2px' : '6px'}}>Contact</div>
                  </Link>
                </VStack>
              </li>
  
              <li>
                {!displayMobileMenu ?
                  <div className="hamburger" onClick={() => { setDisplayMobileMenu(true) }}
                    style={{
                      position: 'relative',
                      top: 5,
                    }}
                  >
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div> :
                  ''
                }
              </li>
            </ul>
  
            {/* CLOSE ICON */}
            <div className="close-icon"
              style={{
                position: 'relative',
                top: -3,
                left: 3,
                userSelect: 'none',
                display: (displayMobileMenu ? 'inline' : 'none'),
                cursor: 'pointer',
              }}
              onClick={() => { setDisplayMobileMenu(false) }}
            >
              &times;
            </div>
          </nav>
        </header>
  
        {/* MOBILE MENU PANEL */}
        <nav className="mobile-menu" style={{
          display: displayMobileMenu ? 'inline' : 'none',
          position: 'relative',
          top: '56px',
          textAlign: 'center',
        }}>
          <ul style={{
            textAlign: 'center',
            fontSize: '20px',
            lineHeight: '40px',
            backgroundColor: 'white',
          }}>
            <li style={{
              width: '100%',
              fontWeight: 500,
              borderTop: '1px solid lightgrey',
              height: '35px',
              cursor: 'pointer',
            }}
              onClick={() => { setDisplayMobileMenu(false) }}
            >
              <Link to={menuRoutes.home}>
                <span style={{color: '#1876f2'}}>Home</span>
              </Link>
            </li>
  
  {/*
            <li style={{
              width: '100%',
              fontWeight: 500,
              borderTop: '1px solid lightgrey',
              height: '35px',
              cursor: 'pointer',
            }}
              onClick={() => { setDisplayMobileMenu(false) }}
            >
              <Link to={menuRoutes.siteConfig}>
                <span style={{color: '#1876f2'}}>Site Config</span>
              </Link>
            </li>
  */}
            
            {/* OLD LOGIN MENU ITEM 
            <li style={{
              width: '100%',
              fontWeight: 500,
              borderTop: '1px solid lightgrey',
              height: '35px',
              cursor: 'pointer',
            }}
              onClick={() => { setDisplayMobileMenu(false) }}
            >
              <Link to={menuRoutes.login}>
                <span style={{color: '#1876f2'}}>User Admin</span>
              </Link>
            </li>
            */}
  
            <li style={{
              width: '100%',
              fontWeight: 500,
              borderTop: '1px solid lightgrey',
              height: '35px',
              cursor: 'pointer',
            }}
              onClick={() => { setDisplayMobileMenu(false) }}
            >
              <Link to={menuRoutes.about}>
                <span style={{color: '#1876f2'}}>About</span>
              </Link>
  
            </li>
            <li style={{
              width: '100%',
              fontWeight: 500,
              borderTop: '1px solid lightgrey',
              borderBottom: '1px solid lightgrey',
              height: '50px',
              cursor: 'pointer',
            }}
              onClick={() => { setDisplayMobileMenu(false) }}
            >
              <Link to={menuRoutes.contact}>
                <span style={{color: '#1876f2'}}>Contact</span>
              </Link>
            </li>
          </ul>
        </nav>

        <Outlet /> 
  
        {/* {!displayMobileMenu ? <AjnCommonLayoutRoutes/> : ''} */}
  
      </div>
      </>
    );
  }