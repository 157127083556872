import React from 'react';
import Version from '../version/version';
import About from '../about/about';
import Home from '../home/home';
import Login from '../login/login';
import ComingSoon from '../coming-soon/coming-soon';
import ConfirmEmail from '../confirm-email/confirm-email';
import Contact from '../contact/contact';
import ForgotPassword from '../forgot-password/forgot-password';
import ChangePassword from '../change-password/change-password';
import RegisterSuccess from '../register-success/register-success';
import Register from '../register/register';
import ClearGlobalState from '../clear-global-state/clear-global-state';
import DisplayGlobalState from '../display-global-state/display-global-state';
import ConfirmEmailLogin from '../confirm-email-login/confirm-email-login';
import SiteConfig from '../site-config/site-config';
import AjnCommonLayout from '../ajn-common-layout/ajn-common-layout';
import { Route, Routes } from 'react-router-dom';

const AjnCommonLayoutRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AjnCommonLayout />}>
                <Route exact path="/" element={<Home />} />
                <Route path="/home/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/change-password/:id" element={<ChangePassword />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/coming-soon" element={<ComingSoon />} />

                <Route path="/register" element={<Register />} />
                <Route path="/register-success" element={<RegisterSuccess />} />
                <Route path="/confirm-email/:id" element={<ConfirmEmail />} />
                <Route path="/clear-global-state" element={<ClearGlobalState />} />
                <Route path="/display-global-state" element={<DisplayGlobalState />} />
                <Route path="/confirm-email-login/:id" element={<ConfirmEmailLogin />} />
                <Route path="/version" element={<Version />} />
                <Route path="/site-config" element={<SiteConfig />}/>

                {/* @@@@ OLD ROUTES - START @@@@ */}

                {/* OLD HOME/PREVIEW ROUTES */}
                {/*
                <Route path="/home/:id" element={<Home />} />
                <Route path="/preview-matt" element={<Home />} />
                <Route path="/preview-wayne" element={<Home />} />
                <Route path="/preview-natalie" element={<Home />} />
                <Route path="/preview/:id" element={<Home />} />
                */}

                {/* ELON ROUTES */}
                {/*
                <Route exact path="/elon/login" element={<ElonLogin />} />
                <Route exact path="/elon/register" element={<ElonRegister />} />
                <Route exact path="/elon/" element={<ElonHome />} />
                <Route exact path="/elon/home" element={<ElonHome />} />
                <Route exact path="/elon/contact" element={<ElonContact />} />
                <Route exact path="/elon/about" element={<ElonAbout />} />
                <Route exact path="/elon/forgot-password" element={<ElonForgotPassword />} />
                */}

                {/* @@@@ OLD ROUTES - END @@@@ */}
            </Route>
        </Routes>
    );
}

export default AjnCommonLayoutRoutes;
