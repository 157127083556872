import axios from 'axios';
import EmailApi from './email-api';
import UserApi from './user-api';

class EmailRegistrationModalApi{

    static WEB_ENVIRONMENT_URL = process.env.REACT_APP_WEB_ENVIRONMENT_URL;

    async submit(emailAddress){

        new UserApi().registerUserEmailOnly(emailAddress);

        this.saveToLocalStorage(emailAddress);

        new EmailApi().sendEmailRegistrationEmail(emailAddress);
    }

    async saveToLocalStorage(emailAddress){
        localStorage.setItem('ajn-user-email-address', emailAddress);
        localStorage.setItem('ajn-email-registration-email-sent', 'true');
    }
}

export default EmailRegistrationModalApi;
