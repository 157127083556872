import { useSelector } from 'react-redux';

const DisplayGlobalState = () => {
  // Access the persisted state from the Redux store
  const persistedState = useSelector(state => state); 

  return (
    <div>
      <div style={{ height: '100px' }}></div>
      <div>
        {/* Render the JSON tree */}
        {JSON.stringify(persistedState, null, 2)}
      </div>
    </div>
  );
};

export default DisplayGlobalState;
