import React, { useRef, useEffect, useState } from 'react';
import {
  Heading,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Text,
  useTheme
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EmailApi from '../../api/email-api';
import UserApi from '../../api/user-api';
import { useParams } from 'react-router-dom';

const ChangePassword = () => {
  const emailRef = useRef(null);
  const theme = useTheme();

  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must contain at least 8 characters, one letter, one number, and one special character'
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Confirm Password must match New Password')
      .required('Confirm New Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSubmitClicked(true);

      const response = await new UserApi().changePassword(id, formik.values.newPassword);

      setSubmitClicked(false);

      if (response.message === 'Password updated successfully') {
        setSubmitSuccess(true);
      }
    },
  });

  return (
    <div style={{ lineHeight: '40px', maxWidth: '50%', margin: '0 auto' }}>
      {/* spacer */}
      <div style={{ height: 80 }}></div>

      <Heading as="h1" size="xl">
        Change Password
      </Heading>

      <div style={{ height: '30px' }}></div>

      <form onSubmit={formik.handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box maxWidth="500px" minWidth="300px">
            <FormControl
              isInvalid={
                formik.errors.newPassword &&
                (formik.touched.newPassword || submitClicked)
              }
            >
              <FormLabel
                htmlFor="newPassword"
                _invalid={{ color: theme.colors.red['500'] }}
              >
                New Password
              </FormLabel>
              <Input
                id="newPassword"
                name="newPassword"
                type="password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                backgroundColor="white"
              />
              <Box width="300px" maxWidth="300px" textAlign="left">
                <FormErrorMessage width="300px" maxWidth="300px">
                  {formik.errors.newPassword}
                </FormErrorMessage>
              </Box>
            </FormControl>

            <FormControl
              isInvalid={
                formik.errors.confirmNewPassword &&
                (formik.touched.confirmNewPassword || submitClicked)
              }
            >
              <FormLabel
                htmlFor="confirmNewPassword"
                _invalid={{ color: theme.colors.red['500'] }}
              >
                Confirm New Password
              </FormLabel>
              <Input
                id="confirmNewPassword"
                name="confirmNewPassword"
                type="password"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                backgroundColor="white"
              />
              <Box width="300px" maxWidth="300px" textAlign="left">
                <FormErrorMessage width="300px" maxWidth="300px">
                  {formik.errors.confirmNewPassword}
                </FormErrorMessage>
              </Box>
            </FormControl>
          </Box>
        </div>

        <Button mt={4} colorScheme="blue" isLoading={submitClicked} type="submit">
          Submit
        </Button>

        {submitSuccess && <Text>Password Changed Successfully</Text>}
      </form>
    </div>
  );
};

export default ChangePassword;
