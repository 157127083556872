import React, { useRef, useEffect, useState } from 'react';
import { 
  Heading, 
  Box, 
  FormControl, 
  FormLabel, 
  Input, 
  FormErrorMessage, 
  Button,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EmailApi from '../../api/email-api';

const ForgotPassword = () => {
  const emailRef = useRef(null);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSubmitClicked(true);
      const response = await new EmailApi().sendChangePasswordEmail(values.email);
      if(response.success){
        setSubmitClicked(false);
        setSubmitSuccessful(true);
      } 
    },
  });

  return (
    <div style={{ lineHeight: '40px', maxWidth: '50%', margin: '0 auto' }}>
      {/* spacer */}
      <div style={{ height: 80 }}></div>

      <Heading as="h1" size="xl">
        Forgot Password
      </Heading>

      <div style={{ height: '30px' }}></div>

      <form onSubmit={formik.handleSubmit}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box maxWidth="500px" minWidth="300px">
            <FormControl isInvalid={formik.errors.email && (formik.touched.email || submitClicked)}>
              <FormLabel 
                htmlFor="email"
                _invalid={{ color: theme.colors.red['500'] }}>
                Email
              </FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                ref={emailRef}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                backgroundColor='white'
              />
              <Box width="300px" maxWidth="300px" textAlign="left">
                <FormErrorMessage width="300px" maxWidth="300px">
                  {formik.errors.email}
                </FormErrorMessage>
              </Box>
            </FormControl>
          </Box>
        </div>

        <Button mt={4} colorScheme="blue" isLoading={submitClicked} type="submit">
          Submit
        </Button>

        {submitSuccessful && (
          <Text>Please check your Inbox</Text>
        )}

      </form>
    </div>
  );
};

export default ForgotPassword;
