import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { 
  Button, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton, 
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setUserOkdCheckInboxMessage,
  setEmailAddressSubmitted
 } from '../../features/userTrial/userTrialSlice';
 import MembershipStateManager from '../../utility/membership-state-manager';

const AIJesusDisclaimerModal = ({
  isOpen, onModalClose, onModalOk
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    new MembershipStateManager(dispatch).syncLocalStorageAndState();
  }, []);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={() => {onModalClose()}}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="25vh"
        marginTop="100px"
      >
        <ModalHeader>Info</ModalHeader>
        <ModalCloseButton 
          onClick={() => {onModalClose()}}>
        </ModalCloseButton>
        <ModalBody>
          <Text mb={4}>
            Responses are modeled on the King James version of The Bible.&nbsp;
            Some questions may be answered as an AI language model based on the context of the question.
          </Text>
          <ModalFooter>
            <Button 
              colorScheme="blue" 
              mr={3} 
              type="submit" 
              onClick={() => {onModalOk()}}>
              Ok
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

AIJesusDisclaimerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onModalOk: PropTypes.func.isRequired,
};

export default AIJesusDisclaimerModal;
