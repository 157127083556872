import { useParams } from "react-router-dom";
import { 
    Heading, 
    Text,
    Box,
    Center
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    setUserIsAnonymous,
    setUserIsEmailOnlyMember,
} from '../../features/userTrial/userTrialSlice';
import {
    setEmailRegistrationEmailConfirmedDate
} from '../../features/membership/membershipSlice';
import UserApi from "../../api/user-api";

const ConfirmEmail = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {

        // include an api call to upsert user record in db
        new UserApi().setEmailRegistrationConfirmationIndicator(id);

        debugger;

        // update the local storage variable 
        localStorage.setItem(
            'ajn-email-registration-email-confirmed-date', new Date());

        // set user as "email user" to global state
        dispatch(setUserIsAnonymous(false))
        dispatch(setUserIsEmailOnlyMember(true));
        dispatch(setEmailRegistrationEmailConfirmedDate(new Date()));

    }, []);

    return (
        <Box>
            <Box mt="85" mb={8}>
                <Heading as="h1" size="xl">
                    Email Confirmed
                </Heading>
            </Box>

            <Center>
                <Box
                    width="100%"
                    minWidth="350px"
                    maxWidth="500px"
                >
                    <Text mb={4}>Email {id} is confirmed!</Text>
                    <Text>
                        <Link to="/" style={{textDecoration:'underline', color:'blue'}}>
                            Ask more questions
                        </Link>
                    </Text>
                </Box>
            </Center>
        </Box>
    )
}

export default ConfirmEmail
