import axios from 'axios';

class EmailApi{

    static WEB_ENVIRONMENT_URL = process.env.REACT_APP_WEB_ENVIRONMENT_URL;
    static API_ENVIRONMENT_URL = process.env.REACT_APP_API_ENVIRONMENT_URL;

    async sendEmailRegistrationEmail(emailAddress){

        const postBody = {
            to: emailAddress
        };

        try {
            const sendEmailApi = 
                `${EmailApi.API_ENVIRONMENT_URL}/api/email/users/${emailAddress}/email-confirmation`;
            const response = await axios.post(sendEmailApi, postBody);
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async sendMembershipSuccessfulEmail(emailAddress){

        const postBody = {
            to: emailAddress
        };

        try {
            const sendEmailApi = 
                `${EmailApi.API_ENVIRONMENT_URL}/api/email/users/${emailAddress}/membership-confirmation`;
            const response = await axios.post(sendEmailApi, postBody);
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    async sendChangePasswordEmail(emailAddress){

        const postBody = {
            to: emailAddress
        };

        try {
            const sendEmailApi = 
                `${EmailApi.API_ENVIRONMENT_URL}/api/email/users/${emailAddress}/reset-password`;
            const response = await axios.post(sendEmailApi, postBody);
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

}

export default EmailApi;
