const ComingSoon = () => {
    return (
        <div style={{
                fontSize:40,
                fontWeight:'bold',
                fontFamily:'arial, helvetica',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'100vw',
                height:'100vh',
                color:'cornflowerblue'
            }}
        >
            ASK JESUS NOW
            <br/>
            IS
            <br/>
            COMING SOON 2
        </div>
    )
}

export default ComingSoon;
