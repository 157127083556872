import { Box, Textarea, Flex } from "@chakra-ui/react";
import { React, useState, useRef, useEffect } from 'react';
import sendbutton from './send-button-enabled-small.png';

const TextArea = ({askQuestion}) => {
  const [question, setQuestion] = useState("");
  const textareaRef = useRef(null);
  const imgSendRef = useRef(null);

  useEffect(() => {
    // Set cursor to the start of the text area on page load
    textareaRef.current.focus();
    textareaRef.current.setSelectionRange(0, 0);
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      (() => {
        askQuestion(question);
        setQuestion('');
      })();
    }
  };

  return (
    <Flex 
      direction="row" 
      align="center" 
      justify="center"
      >

      <Textarea
        width="100%"
        backgroundColor="white"
        placeholder="Enter your question to Jesus here"
        ref={textareaRef}
        value={question}
        onChange={(x) => setQuestion(x.target.value)}
        onKeyDown={handleKeyDown}
        
      />

      {/* AIRPLANE SEND BUTTON */}
      <Box ml="2">
        <img
          onClick={ () => { 
            askQuestion(question);
            setQuestion('');
          }}
          alt="Ask Question"
          src={sendbutton}
          style={{
            width: '30px',
            height: '30px',
            cursor: 'pointer',
            positon: 'relative',
          }}
          ref={imgSendRef}
        />
      </Box>
    </Flex>
  );
};

export default TextArea;
