import axios from 'axios';

class UserApi{

    static API_ENVIRONMENT_URL = process.env.REACT_APP_API_ENVIRONMENT_URL;

    async getUserIPAddress() {
      try {
        const getUserIPEndpoint = `${UserApi.API_ENVIRONMENT_URL}/api/users/get-ip`;
        const response = await axios.get(getUserIPEndpoint);

        return response.data;
      } catch (error) {
          console.log(error);
          return {msg:'api no work', error};
      }
    }

      getUserEmailAddress() {
        
        const userEmailAddress = localStorage.getItem('ajn-user-email-address');
        return userEmailAddress;
      }

    async getUser(emailAddress){

        try {
            const getUserEndpoint = `${UserApi.API_ENVIRONMENT_URL}/api/users/${emailAddress}`;
            const response = await axios.get(getUserEndpoint);
            return response.data;
        } catch (error) {
            console.log(error);
            return {msg:'api no work', error};
        }
    }

    async createUser(user){
  
        try {

          const url = `${UserApi.API_ENVIRONMENT_URL}/api/users/register`;
          const response = await fetch(url, {
              method: 'POST',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(user)
          });
      
          if (response.ok) {
              const data = await response.json();

              // convert to user object?
              return data.message;

          } else {
              console.error('API RESPONSE ERROR:', response.status);
              // Handle error response accordingly
          }
        } catch (error) {
          console.error('!!! => /api/user/register failed with the following error:', error);
          // Handle error case accordingly
        }
    }

    async registerUserEmailOnly(emailAddress){

      const postBody = {
          emailAddress
      };
      
      try {
          const api = 
              `${UserApi.API_ENVIRONMENT_URL}/api/users/register-email-only`;

          const response = await axios.post(api, postBody);
          return response.data;
      } catch (error) {
          console.log(error);
          return null;
      }
  }

  async changePassword(emailAddress, password){

    const postBody = {
      password
    };

    try {
      const api = 
          `${UserApi.API_ENVIRONMENT_URL}/api/users/${emailAddress}/password`;

      const response = await axios.put(api, postBody);

      return response.data;
    } catch (error) {

      console.log(error);
      return null;
    }
  }

  async setEmailRegistrationConfirmationIndicator(emailAddress){

    const postBody = {};

    try {
      const api = 
          `${UserApi.API_ENVIRONMENT_URL}/api/users/${emailAddress}/confirm-email`;

      const response = await axios.put(api, postBody);

      return response.data;
    } catch (error) {

      console.log(error);
      return null;
    }
  }
}

export default UserApi;