import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
// import { persistor } from './path/to/your/store'; // Import the persistor from your Redux store
import { persistor } from '../../app/store'; // Import the persistor from your Redux store
import {
  Button
} from '@chakra-ui/react';
//import { useState } from 'react';

const ClearGlobalState = () => {
    const dispatch = useDispatch();

    const [clearStateSuccess, setClearStateSuccess] = useState('');
  
    const handleClearState = () => {

      // clear state stored via redux toolkit and redux-persist
      persistor.purge()
        .then(() => {
          // Dispatch any additional actions after clearing the state if needed
          dispatch({ type: 'CLEAR_STATE_SUCCESS' });
        })
        .catch((error) => {
          console.error('Failed to clear state:', error);
          dispatch({ type: 'CLEAR_STATE_FAILURE' });
        });
    
      // clear other independent local storage variables
      localStorage.removeItem('ajn-user-email-address');
      localStorage.removeItem('ajn-number-of-trial-questions-asked');
      localStorage.removeItem('ajn-registration-email-sent'); // previous name
      localStorage.removeItem('ajn-email-registration-email-sent');
      localStorage.removeItem('ajn-email-registration-email-confirmed-date');
      localStorage.removeItem('ajn-user-auth-token');
      localStorage.removeItem('__paypal_storage__');
      localStorage.removeItem('ajn-number-of-trial-questions-asked-new');

      
      // clear sesionStorage vars used for registration since they are no longer needed
      
      setClearStateSuccess('global state and localStorage items cleared');
    };
  
    return (
      <div>
        <div style={{height:200}}></div>

        <Button 
          colorScheme="blue" 
          my={5}
          type="submit"
          onClick={handleClearState}
          >
            Clear Global State
        </Button>

        <div>
          {clearStateSuccess}
        </div>

      </div>
    );
  };
  
  export default ClearGlobalState;