import UserApi from '../api/user-api';
import {
    setIsAnonymousUser,
    setIsPaidUser,
    setIsEmailOnlyUser,
    setEmailRegistrationEmailSent,
    setEmailRegistrationEmailConfirmed,
    setEmailRegistrationEmailConfirmedDate,
    //setEmailRegistrationEmailSentButNotConfirmed,
    setNumberOfTrialQuestionsAsked,
} from '../features/membership/membershipSlice';

class MembershipStateManager {
  constructor(dispatch) {
    this.dispatch = dispatch;

    const isPaidUser = !!localStorage.getItem('ajn-user-auth-token');

    this.state = {
        isPaidUser
    }

    this.syncLocalStorageAndState();
  }

  async getUserFromDB(emailAddress){
    return new UserApi().getUser(emailAddress);
  }

  syncLocalStorageAndState(){

    // if user is paid member then clear trial-questions-asked from local storage
    if(this.state.userIsPaidMember) localStorage.removeItem(
      'ajn-number-of-trial-questions-asked');

    this.setLocalStorageValuesToState();
  }

  setLocalStorageValuesToState(){

    // email registration email sent
    const emailRegistrationEmailSent = !!localStorage.getItem(
      'ajn-email-registration-email-sent'
    );
    this.dispatch(setEmailRegistrationEmailSent(emailRegistrationEmailSent));

    // email registration email confirmed date
    const emailRegistrationEmailConfirmedDate = localStorage.getItem(
      'ajn-email-registration-email-confirmed-date'
    )
    if(!!emailRegistrationEmailConfirmedDate)
      this.dispatch(
        setEmailRegistrationEmailConfirmed(emailRegistrationEmailConfirmedDate)
      );

    // // email registration email sent but not confirmed
    // this.dispatch(
    //   setEmailRegistrationEmailSentButNotConfirmed(
    //     emailRegistrationEmailSent && !emailRegistrationEmailConfirmedDate
    //   )
    // );

    const isPaidUser = this.state.isPaidUser;
    this.dispatch(
        setIsPaidUser(isPaidUser)
    );

    // is anonymous user
    this.dispatch(
        setIsAnonymousUser(
            !isPaidUser && !emailRegistrationEmailConfirmedDate
        )
    );

    // is email only user
    this.dispatch(
        setIsEmailOnlyUser(
          emailRegistrationEmailConfirmedDate && !isPaidUser 
        )
    );
  }

  incrementTrialQuestionsCount(){

    // if user is paid member then return 
    if(this.state.isPaidUser) return;

    // update number of trial questions asked
    let numberOfTrialQuestionsAsked = localStorage.getItem(
      'ajn-number-of-trial-questions-asked-new');
    if(!numberOfTrialQuestionsAsked) numberOfTrialQuestionsAsked = 0;
    else numberOfTrialQuestionsAsked = parseInt(numberOfTrialQuestionsAsked);
    const newQuestionCount = numberOfTrialQuestionsAsked + 1;

    // update question count in local storage
    localStorage.setItem('ajn-number-of-trial-questions-asked-new', newQuestionCount);

    this.dispatch(setNumberOfTrialQuestionsAsked(newQuestionCount));
  }

  refreshTokenTimeout = () => {
    localStorage.setItem('ajn-user-auth-token', Date.now());
  }
}

export default MembershipStateManager;
