import React, { useEffect, useState, useRef } from 'react';
import EmailApi from '../../../api/email-api';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserApi from '../../../api/user-api';
import { resetRegistration, setSelectedPlanCode } from '../../../features/registration/registrationSlice';
import { Select } from '@chakra-ui/react';
import { persistor } from '../../../app/store'; // redux store persistor

const PayPalButton = ({
    firstName, 
    lastName, 
    emailAddress, 
    password, 
    charityOfChoice, 
    termsAndConditions,
    selectedPlanId,
    selectedPlanCode,
    registerCallbackHandler,
  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paypalScriptLoaded, setPayPalScriptLoaded] = useState(false);
  const REACT_APP_PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const REACT_APP_PAYPAL_PLAN_ID = process.env.REACT_APP_PAYPAL_PLAN_ID;

  const finalizeCreateUser = () => {

    createUser();

    // send email
    new EmailApi().sendMembershipSuccessfulEmail(emailAddress);

    // clear all state
    clearGlobalState();

    // redirect register success page,
    navigate('/register-success');
  };

  const createUser = () => {
    const firstName = sessionStorage.getItem('registration.firstName');
    const lastName = sessionStorage.getItem('registration.lastName');
    const emailAddress = sessionStorage.getItem('registration.emailAddress');
    const password = sessionStorage.getItem('registration.password');
    const charityOfChoice = sessionStorage.getItem('registration.charityOfChoice');
    const selectedPlanId = sessionStorage.getItem('registration.selectedPlanId');
    const selectedPlanCode = sessionStorage.getItem('registration.selectedPlanCode');
    const termsAndConditions = true;
    
    const user = {
      firstName,
      lastName,
      emailAddress,
      password,
      charityOfChoice,
      termsAndConditions,
      selectedPlanId,
      selectedPlanCode,
      registrationDate: Date.now()
    };

    // post user for full membership registration
    new UserApi().createUser(user);
  };

  // if success then its valid to clear global state
  const clearGlobalState = () => {
      clearReduxState();
      clearLocalStorage();
      clearSessionStorage();
    };

    const clearReduxState = () => {
      // clear state stored via redux toolkit and redux-persist
      persistor.purge()
        .then(() => {

          // Dispatch any additional actions after clearing the state if needed
          dispatch({ type: 'CLEAR_STATE_SUCCESS' });
        })
        .catch((error) => {

          console.error('Failed to clear state:', error);
          dispatch({ type: 'CLEAR_STATE_FAILURE' });
        });
    }

    const clearLocalStorage = () => {
      // clear other independent local storage variables from localStorage
      localStorage.removeItem('ajn-user-email-address');
      localStorage.removeItem('ajn-number-of-trial-questions-asked');
      localStorage.removeItem('ajn-email-registration-email-confirmed-date');
      localStorage.removeItem('ajn-registration-email-sent'); // previous name
      localStorage.removeItem('ajn-email-registration-email-sent');
      localStorage.removeItem('ajn-user-auth-token');
      localStorage.removeItem('__paypal_storage__');
    }

    const clearSessionStorage = () => {
      sessionStorage.removeItem('registration.firstName');
      sessionStorage.removeItem('registration.lastName');
      sessionStorage.removeItem('registration.emailAddress');
      sessionStorage.removeItem('registration.password');
      sessionStorage.removeItem('registration.charityOfChoice');
      sessionStorage.removeItem('registration.selectedPlanId');
      sessionStorage.removeItem('registration.selectedPlanCode');
    } 

  const selectedPlanIdRef = useRef('');

  useEffect(() => {
    selectedPlanIdRef.current = selectedPlanId;
  }, [selectedPlanId]);

  useEffect(() => {

    const prodPayPalUrl = `https://www.paypal.com/sdk/js?client-id=${REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
    const sandboxPayPalUrl = `https://www.sandbox.paypal.com/sdk/js?client-id=${REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
    //const sandboxPayPalUrl = `https://www.paypal.com/sdk/js?client-id=${REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription&disable-funding=credit,card`;

    if (!paypalScriptLoaded) {
      const script = document.createElement('script');
      //script.src = sandboxPayPalUrl;
      script.src = prodPayPalUrl;
      script.async = true;
      script.onload = () => {
        setPayPalScriptLoaded(true);
      };

      document.body.appendChild(script);
    }

    if(firstName) sessionStorage.setItem('registration.firstName', firstName);
    if(lastName) sessionStorage.setItem('registration.lastName', lastName);
    if(emailAddress) sessionStorage.setItem('registration.emailAddress', emailAddress);
    if(password) sessionStorage.setItem('registration.password', password);
    if(charityOfChoice) sessionStorage.setItem('registration.charityOfChoice', charityOfChoice);
    if(selectedPlanId) sessionStorage.setItem('registration.selectedPlanId', selectedPlanId);
    if(selectedPlanCode) sessionStorage.setItem('registration.selectedPlanCode', selectedPlanCode);

    return () => {
      // Clean up the script element when the component unmounts
      if (paypalScriptLoaded) {
        const script = document.querySelector('script[src*="paypal.com/sdk/js"]');
        if(script != null && script.parentNode != null){
          script.parentNode.removeChild(script);
        }
      }
    };
  }, [
    paypalScriptLoaded, 
    firstName, 
    lastName, 
    emailAddress, 
    password, 
    charityOfChoice, 
    termsAndConditions,
    selectedPlanId,
    selectedPlanCode,
  ]);

  useEffect(() => {
    if (paypalScriptLoaded) {

      const renderPayPalButtons = () => {
        // Remove the previous set of PayPal buttons
        const previousButtonsContainer = document.getElementById('paypal-button-container');
        while (previousButtonsContainer.firstChild) {
          previousButtonsContainer.firstChild.remove();
          //previousButtonsContainer.firstChild.style.display = 'none';
        }

        window.paypal.Buttons({
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: selectedPlanId
            });
          },
          onApprove: function (data, actions) {

            // finalize user creation and redirect
            finalizeCreateUser();
            
          },
          onCancel: function (data) {

            // do nothing
          },
          onError: function (err) {
            alert('User signup error - please try again!');
          }
        }).render('#paypal-button-container');
      }
      renderPayPalButtons();
    }
  }, [paypalScriptLoaded, selectedPlanId]);

  return (
    <div>
      <div style={{ height: '20px' }}></div>

      <div id="paypal-button-container"></div>
    </div>
  );
};

export default PayPalButton;
