import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
        personalInfo: {
          firstName:'', 
          lastName: '', 
          emailAddress:'', 
          password:''
        },
        personalInfoIsValid: false,
        accordionItemIndex: 0,
        paymentMade: false,
        termsAndConditions: false,
        billingInfoDependenciesResolved: false,
        forceRefresh: '',
        selectedPlanId: '',
        selectedPlanCode:''
    },
}

export const registrationSlice = createSlice({
  name: 'registrationElon',
  initialState,
  reducers: {
    setAccordionItemIndex: (state, action) => {
      state.value.accordionItemIndex = action.payload;
    },
    setPersonalInfo: (state, action) => {
      state.value.personalInfo = action.payload;
    },
    setPersonalInfoKeyValue: (state, action) => {
      state.value.personalInfo[action.payload.key] = action.payload.value;
    },
    setTermsAndConditionsAccepted: (state, action) => {
      state.value.termsAndConditions = action.payload;
    },
    setPaymentMade: (state, action) => {
      state.value.paymentMade = action.payload;
    },
    setBillingInfoDependenciesResolved: (state, action) => {
      state.value.billingInfoDependenciesResolved = action.payload;
    },
    setForceRefresh: (state, action) => {
      state.value.forceRefresh = action.payload;
    },
    setSelectedPlanId:  (state, action) => {
      state.value.selectedPlanId = action.payload;
    },
    setSelectedPlanCode:  (state, action) => {
      state.value.selectedPlanCode = action.payload;
    },
    resetRegistration: (state, action) => {
      state.value.accordionItemIndex = 0;

      state.value.personalInfo.firstName = '';
      state.value.personalInfo.lastName = '';
      state.value.personalInfo.emailAddress = '';
      state.value.personalInfo.password = '';

      state.value.charityOfChoice = '';
      state.value.termsAndConditions = false;
      state.value.paymentMade = false;
      state.value.billingInfoDependenciesResolved = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
    setAccordionItemIndex,
    setPersonalInfo,
    setPersonalInfoKeyValue,
    setTermsAndConditionsAccepted,
    setPaymentMade,
    setBillingInfoDependenciesResolved,
    setForceRefresh,
    resetRegistration,
    setSelectedPlanId,
    setSelectedPlanCode,
} = registrationSlice.actions

export default registrationSlice.reducer
