import { useParams } from "react-router-dom";
import { Heading, Text } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { useEffect } from "react";

const ConfirmEmailLogin = () => {
    const { id } = useParams();

    useEffect(() => {

        //

    }, []);

    return (

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', minWidth: '450px' }}>
            <div>
                {/* spacer */}
                <div style={{ height: 80, display: 'flex', justifyContent: 'center' }}></div>
                <Heading as="h1" size="xl">
                    Email Confirmed
                </Heading>
                <div style={{ height: '30px' }}></div>
                <Text mb={4}>Email {id} is confirmed!</Text>
                <Text>
                    <Link to="/login" style={{textDecoration:'underline', color:'blue'}}>
                        Log In to Continue
                    </Link>
                </Text>
            </div>
        </div>
    )
}

export default ConfirmEmailLogin
