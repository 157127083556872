import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  Button, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  ModalCloseButton, 
  Text,
  VStack
} from '@chakra-ui/react';
import { Link} from 'react-router-dom';

const MembershipRequiredModal = ({ openModal, onModalCancel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  const FREE_TRIAL_PERIOD_DAYS = parseInt(process.env.REACT_APP_FREE_TRIAL_PERIOD_DAYS);

  useEffect(() => {
    setIsOpen(openModal);
  }, [openModal]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div>
      <Modal 
        isOpen={isOpen} 
        size="md" 
        initialFocusRef={inputRef} 
        onClose={() => {onModalCancel()}}>
        <ModalOverlay />
        <ModalContent
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="30vh"
          marginTop="100px"
        >
          <ModalHeader>Registration is required</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} marginBottom={5}>
              <Text>
                We hope that you've enjoyed your {FREE_TRIAL_PERIOD_DAYS}-day trial membership!
                &nbsp;
                Click the Register button below to continue chatting.
              </Text>
            </VStack>

            <form>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} type="submit">
                    <Link to="/register">
                        Register
                    </Link>
                </Button>
                <Button variant="ghost" onClick={() => {onModalCancel()}} data-parsley-excluded>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

MembershipRequiredModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalCancel: PropTypes.func.isRequired,
};

export default MembershipRequiredModal;
