import React from 'react';

const AndrewCiccarelliPortfolio = () => {
    return (

        <div style={{ lineHeight: '40px', maxWidth: '50%', margin: '0 auto' }}>
            {/* spacer */}
            <div style={{ height: 80 }}></div>

            <div>ANDREW CICCARELLI'S PORTFOLIO</div>
            
        </div>
    )
}

export default AndrewCiccarelliPortfolio;
