import { React, useState } from 'react';
import {
  Box,
  Heading,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  Text,
  Select,
  useTheme,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { 
  setAccordionItemIndex, 
  setCharityOfChoice } from '../../../features/registration/registrationSlice';


const CharityOfChoice = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [nextClicked, setNextClicked] = useState(false);

  const charityOfChoice = useSelector((state) => state.registration.value.charityOfChoice);

  const validationSchema = Yup.object().shape({
    charityOfChoice: Yup.string().required('Charity selection is required'),
  });

  const handleSubmit = (values) => {
    if (!formik.isValidating) {
      dispatch(setCharityOfChoice(values.charityOfChoice));
      // continue to next accordion item
      dispatch(setAccordionItemIndex(2));
    } else {
      setNextClicked(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      charityOfChoice: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        id="charityOfChoice"
        isInvalid={formik.errors.charityOfChoice && (formik.touched.charityOfChoice || nextClicked)}
      >
        <Text>
          $1/month of your monthly membership fee is donated to your charity of choice
        </Text>

        <FormLabel
          htmlFor="charityOfChoice"
          _invalid={{ color: theme.colors.red['500'] }}>
          Charity
        </FormLabel>
        <Select
          name="charityOfChoice"
          value={charityOfChoice}
          onChange={(x) => {
            dispatch(setCharityOfChoice(x.target.value));
            formik.setFieldValue('charityOfChoice', x.target.value);
          }}
          onBlur={formik.handleBlur}
        >
          <option value="">Select a charity</option>
          <option value="GW">Goodwill</option>
          <option value="MAW">Make-a-Wish Foundation</option>
          <option value="SGK">Susan G. Komen for the cure</option>
          <option value="SA">Salvation Army</option>
          <option value="U24">United24 (Ukraine)</option>
        </Select>
        <FormErrorMessage>{formik.errors.charityOfChoice}</FormErrorMessage>
      </FormControl>

      <div style={{ height: '20px' }}></div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button colorScheme="blue" mr={3} type="submit">
          Next
        </Button>
      </div>
    </form>
  );
};

export default CharityOfChoice;
