import { 
    Heading, 
    Text,
    Box,
    VStack
} from '@chakra-ui/react';

const Contact = () => {
    return (
        <Box mx="4">

            <Box mt="85" mb="8">
            <Heading as="h1" size="xl">
                Contact
            </Heading>
            </Box>

            <VStack lineHeight='25px'>
                <Text mb="5" mt="1">Questions, Comments, Testimonials?</Text>
                <Text>Feel free to send an email to info@AskJesusNow.chat!</Text>
            </VStack>
        </Box>
    )
}

export default Contact;
